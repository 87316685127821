.about {
  p.large {
    font-size: 125%;
    line-height: 125%;
    margin: 12px 36px;
  }
  .section {
    border-top: solid 1px black;
    margin-top: 6px;
  }
  .legal {
    width: 45%;
    float: left;
    p {
      margin-left: 10px;
    }
    li {
      margin-bottom: 3px;
    }
  }
  .gist {
    width: 55%;
    float: right;
    h6 {
      font-size: 80%;
      color: #666;
      margin-left: 20px;
    }
    p {
      font-size: 150%;
      line-height: 125%;
      font-weight: bold;
      margin: 0px 50px;
    }
  }
  p,li {
    font-size: 80%;
    line-height: 12px;
    text-align: justify;
  }
}
