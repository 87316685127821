@import "../design/layout.scss";

.App {
  text-align: center;
}

.App-intro {
  font-size: large;
}

.rbc-calendar {
  flex: 0.5;
  min-height: 400px;
}

.sdc-main-content {
   margin: 12px;
   margin-right: 0;
   padding-right: 12px;
   overflow-x: hidden;
   overflow-y: scroll;
   position: absolute;
   top: 60px;
   bottom: $footer-height;
   left: 0;
   right: 0;
}
