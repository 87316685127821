$sdc-light-blue: #e5eeff;
$sdc-blue: #cdf;

$white:    #fff !default;
$gray-100: #f9fafb !default;
$gray-200: #eaecf0 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced2da !default;
$gray-500: #adb2bd !default;
$gray-600: #6d737e !default;
$gray-700: #484d56 !default;
$gray-800: #353941 !default;
$gray-900: #22252a !default;
$black:    #000 !default;

