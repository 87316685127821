$brand-color: #666;

//$primary: #a58;

$btn-focus-width:             0;

$navbar-padding-y:                  0;
$navbar-padding-x:                  0;
$nav-link-padding-y:                .9rem;

$navbar-light-color:                rgba($brand-color, .8);
$navbar-light-hover-color:          rgba($brand-color, 1);
$navbar-light-active-color:         rgba($brand-color, 1);
$navbar-light-disabled-color:       rgba($brand-color, .4);

$form-group-margin-bottom:          0;

@import "node_modules/bootstrap/scss/bootstrap";

.btn-outline-primary:hover {
  background-color: #e5eeff;
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.btn-outline-secondary.disabled {
  background-color: #cdf;
}

.form-group {
  margin-bottom: 8px;
}
.col-form-label {
  font-size: 85%;
  color: $gray-600;
  padding-top: 4px;
  padding-bottom: 0;
}

.nav-tabs {
  border-bottom-color: $gray-500;
  .nav-link {
    padding: 4px 12px;
  }
}
.nav-tabs .nav-link {
  border-top-color: $gray-200;
  border-left-color: $gray-200;
  border-right-color: $gray-200;
  border-bottom-color: $gray-500;
  &:hover {
    border-top-color: $gray-400;
    border-left-color: $gray-400;
    border-right-color: $gray-400;
  }
  &.active {
    border-top-color: $gray-500;
    border-left-color: $gray-500;
    border-right-color: $gray-500;
  }
  margin-right: 8px;
}
.tab-pane {
  padding: 8px;
  border-left: solid 1px $gray-500;
  border-right: solid 1px $gray-500;
  border-bottom: solid 1px $gray-500;
  min-height: 40vh;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.gmcd-select.Select--multi .Select-value {
  background-color: $gray-200;
  color: $gray-900;
  border-color: $gray-400;
  .Select-value-icon {
    background-color: theme-color-level("danger", -10);
    border-color: $gray-400;
  }
}

.entry-filter {
  padding: 4px;
  border: solid 1px $gray-400;
  margin-bottom: 2px;
  .remove-filter {
    color: theme-color-level("danger", -8);
    &:hover {
      color: theme-color-level("danger", -1);
    }
  }
  &.disabled {
    font-size: 85%;
    border-color: transparent;
  }
}
.md-self {
  color: #090;
  font-weight: bold;
}
.md-label {
  font-weight: bold;
  &.bordered {
    border: solid 1px #ddd;
    padding: 4px;
    margin-right: 8px;
  }
}
.md-link {
  color: #009;
  font-weight: bold;
  cursor: pointer;
  white-space: pre;
  &:hover {
    text-decoration: underline;
  }
  &.bordered {
    border: solid 1px #ddd;
    padding: 4px;
    margin-right: 8px;
  }
  .fa {
    margin-right: 4px;
  }
}
.details-header {
  .toggle {
    color: $gray-400;
    margin-right: 12px;
    cursor: pointer;
    border: solid 1px $gray-200;
    &:hover {
      color: $gray-700;
      border: solid 1px $gray-400;
    }
    .fa {
      margin: 0 4px;
    }
  }
  .fa {
    margin-right: 8px;
  }
}
.markdown-header {
  position: relative;
  .toggle {
    color: $gray-400;
    cursor: pointer;
    border: solid 1px $gray-200;
    &:hover {
      color: $gray-700;
      border: solid 1px $gray-400;
    }
    .fa {
      margin: 0 4px;
    }
  }
}

.type-panel {
  &.expandable {
    h4 , .h4 {
      cursor: pointer;
    }
  }
  h4 , .h4 {
    &.collapsed {
      margin-bottom: 4px;
      border-bottom: solid 1px $gray-400;
    }
    &.expanded {
      margin-bottom: 0;
    }
    .fa {
      margin-right: 8px;
    }
  }
}
textarea.form-control {
  padding: 0.25em 4px;
  border: solid 1px #ddbbcf;
  border-radius: 0;
  &:focus {
    color: #900;
    background-color: #e5eeff;
  }
}
.inline-help-btn {
  color: #9bf;
  cursor: help;
  &:hover {
    color: #05f;
  }
}

.panel-toolbar {
  margin-bottom: 8px;
  .btn-sm , .btn-group-sm > .btn {
    font-size: 0.75rem;
    padding: 0.1rem 0.25rem;
  }
}
