@import "../../design/colors.scss";

.letter-preview {
  border: solid 1px #ccc;
  padding: 8px;
  .betreff , .anrede , .text , .gruss , .signed {
    cursor: pointer;
    &:hover {
      background-color: $sdc-light-blue;
    }
  }
  .text {
    margin-top: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.letter-actions {
  margin-top: 12px;
  text-align: center;
  .btn-default {
    padding: .125rem .25rem;
    border: solid 1px $gray-300;
    &:hover {
      border-color: $gray-500;
      background-color: $gray-200;
    }
  }
}
