body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
a {
  color: #333;
}
.xxbtn-info {
  background-color: #9bf;
  border-color: #37f;
}
.btn-danger {
  color: black;
  background-color: #fcc;
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
  background-color: #f99;
}
