.ReactTable {
  .rt-th , .rt-td {
    margin: 5px 4px 2px;
    padding: 1px;
    overflow: visible;
  }
  .rt-th.left {
    text-align: left;
  }
  .rt-td {
    &.centered {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
    &.padded {
      margin-top: 0.85rem;
    }
  }
  .rt-thead .rt-th.-cursor-pointer {
    cursor: n-resize ! important;
  }
  &.-highlight {
    .rt-thead , .rt-tbody {
      .rt-tr .sb-actions {
        opacity: 0.3;
        .sb-action-button {
          opacity: 0.7;
          color: #333;
          cursor: pointer;
          padding: 1px 4px;
          margin: 0 2px;
          border: solid 1px #ccc;
          &:hover {
            color: #000;
            opacity: 1.0;
            border-color: #999;
          }
        }
      }
    }
  }
  &.-highlight .rt-thead .rt-tr:hover .sb-actions ,
  &.-highlight .rt-tbody .rt-tr:hover .sb-actions {
    opacity: 1.0;
  }
  &.-highlight.selectable .rt-tbody .rt-tr {
    cursor: zoom-in;
  }
  &.-highlight .rt-tbody .rt-tr.selected {
    font-weight: bold;
    background-color: #e5eeff;
  }
  &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background-color: #ddd;
  }
  &.-highlight .rt-tbody .rt-tr.selected.locked {
    background-color: #f2e5ff;
  }
  &.-highlight .rt-tbody .rt-tr.selected.locked:not(.-padRow):hover {
    background-color: #e5ccff;
  }
  &.-highlight .rt-tbody .rt-tr.selected:not(.-padRow):hover {
    background-color: #ccdeff;
  }
  &.-highlight .rt-tbody .rt-tr.locked {
    background-color: #fee;
    &:hover:not(.-padRow) {
      background-color: #edd;
    }
  }
  &.-highlight .rt-tbody .rt-tr.locked:not(.-padRow):hover {
    background-color: #fcc;
  }
}
