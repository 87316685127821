@import '../../design/colors.scss';


.panel-heading {
  &.collapsed {
    margin-bottom: 4px;
    border-bottom: solid 1px $gray-400;
    cursor: zoom-in;
  }
  &.expanded {
    margin-bottom: 0;
    cursor: zoom-out;
  }
  &> i.fa {
    margin-right: 12px;
    color: #999;
    &.btn {
      margin-right: 0;
    }
  }
}
