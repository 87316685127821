.issue-ident {
  cursor: pointer;
  border: solid 1px #eee;
  border-radius: 4px;
  padding: 3px 8px 1px;
  width: 80%;
  display: inline-block;
  &:hover {
    background-color: #eee;
    border: solid 1px #ccc;
  }
}
