.rbc-day-slot .rbc-event {
  border-width: 2px;
}
.rbc-day-slot .rbc-event.rbc-selected {
  border-width: 6px;
}
.rbc-event.holiday {
  background-color: #fcc;
  color: #900;
  border-radius: 0;
}
.rbc-time-slot.holiday {
  background-color: #fee;
}
.rbc-event.off-day {
  background-color: #cfc;
  color: #090;
  border-radius: 0;
}
.rbc-event.done-day {
  background-color: #dfd;
  color: #090;
  border-radius: 0;
}
.rbc-time-slot.off-day {
  background-color: #e4ffe4;
}
.rbc-day-slot .rbc-time-slot.selected {
  background-color: #e5eeff;
}
.rbc-day-slot .rbc-time-slot.selected.holiday {
  background-color: #fcf;
}
.rbc-day-slot .rbc-time-slot.selected.off-day {
  background-color: #cff;
}
.rbc-day-slot .rbc-time-slot.selected-day {
  background-color: #f0f5ff;
}
.rbc-day-slot .rbc-time-slot.selected-day.holiday {
  background-color: #ffe5ff;
}
.rbc-day-slot .rbc-time-slot.selected-day.off-day {
  background-color: #e5ffff;
}
.rbc-event-allday.work-done {
  background-color: #bbb;
  font-weight: bold;
}
.rbc-event-allday.work-done .rbc-event-content {
  color: #666;
}
.rbc-month-row .rbc-day-bg.weekend {
  background-color: #f5f5f5;
}
.rbc-month-row .rbc-day-bg.weekend.rbc-off-range-bg {
  background-color: #ddd;
}
.rbc-month-row .rbc-day-bg.holiday.rbc-off-range-bg {
  background-color: #edd;
}
.rbc-month-row .rbc-day-bg.holiday.weekend {
  background-color: #edd;
}
.rbc-allday-cell .rbc-day-bg.selected , .rbc-header.selected {
  background-color: #e5eeff;
}
.rbc-month-row .rbc-day-bg.selected {
  background-color: #f0f5ff;
  border: solid 2px #cdf;
}
.rbc-allday-cell .rbc-day-bg.selected.rbc-today , .rbc-header.selected.rbc-today {
  background-color: #fec;
}
.rbc-month-row .rbc-day-bg.selected.rbc-today {
  background-color: #e5eeff;
  border: solid 2px #fa5;
}
.rbc-allday-cell .rbc-day-bg.selected.holiday , .rbc-header.selected.holiday {
  background-color: #fcf;
}
.rbc-month-row .rbc-day-bg.selected.holiday {
  background-color: #ffe5ff;
  border: solid 2px #f9f;
}
.rbc-allday-cell .rbc-day-bg.holiday , .rbc-header.holiday {
  background-color: #fee;
}
.rbc-month-row .rbc-day-bg.holiday {
  background-color: #fee;
  border: solid 2px #fcc;
}
.rbc-event-allday.holiday {
  background-color: #fcc;
}
.rbc-allday-cell .rbc-day-bg.selected.off-day , .rbc-header.selected.off-day {
  background-color: #cff;
}
.rbc-month-row .rbc-day-bg.selected.off-day {
  background-color: #e5ffff;
  border: solid 2px #9ff;
}
.rbc-allday-cell .rbc-day-bg.off-day , .rbc-header.off-day {
  background-color: #e4ffe4;
}
.rbc-month-row .rbc-day-bg.off-day {
  background-color: #e4ffe4;
  border: solid 2px #beb;
}
.rbc-event-allday.off-day {
  background-color: #beb;
}
.rbc-event-allday.selected {
  background-color: #9bf;
  font-weight: bold;
}

.rbc-today {
  background-color: #fec;
}
.rbc-month-row .rbc-today {
  border: solid 2px #fa5;
}

.rbc-event-label {
  color: #333;
}
.rbc-event-content {
  color: #333;
}

.rbc-date-cell > a , .rbc-date-cell > a:visited {
  color: #33c;
  text-decoration: underline;
}
.rbc-date-cell.rbc-off-range > a , .rbc-date-cell.rbc-off-range > a:visited {
  color: #88a;
}
