@import "src/design/colors.scss";

.navbar-gmcd {
  background-color: $sdc-light-blue;
  border-color: $sdc-blue;
  .navbar-brand {
    color: #666;
    b {
      padding-left: 8px;
      font-size: 26px;
    }
  }
  .navbar-nav li a.active {
    color: #333;
    background-color: #cdf;
  }
  .logout-button {
    margin-top: 12px;
  }
}

a.navbar-brand {
  padding: 10px 12px;
}

