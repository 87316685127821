@import "src/design/colors.scss";
@import "src/design/layout.scss";

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  background-color: $sdc-light-blue;
  line-height: $footer-height;
  border-top: solid 2px $sdc-blue;
  .nav-link {
    padding: 0 1rem;
  }
  .navbar-text {
    padding: 3px 28px;
    color: #ccc;
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    &:hover {
      color: #999;
    }
  }
}
