.SheetSelector__control {
  border-radius: 1px;
  height: 12px;
  border-color: #ddbbcf;
}
.Select--single {
  margin: -2px;
}
.Select--single > .Select-control .Select-value {
  padding: 0;
  padding-top: 1px;
  padding-left: 4px;
  line-height: 1.5em;
  height: 1.5em;
}
.SheetSelector__input {
  height: 1.5em;
}
.SheetSelector__input > input {
  padding: 0;
  height: 1.5em;
}
.Select-arrow-zone {
  height: 12px;
}
