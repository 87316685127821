@import "../../design/colors.scss";

.postal-view {
  &.target {
    font-size: 14px;
    margin-top: 12px;
    border: solid 1px #ccc;
    border-radius: 4px;
    padding: 4px;
  }
  width: 50%;
  font-size: 12px;
  line-height: 1.1em;
  cursor: pointer;
  &:hover {
    background-color: $sdc-light-blue;
  }
  .city {
    font-weight: bold;
    margin-top: 2px;
  }
}
.datum {
  text-align: right;
}
.betreff , .anrede , .anlagen {
  margin-top: 12px;
}
.signed {
  margin-top: 24px;
}
